html.theme-Light {

  $border-color: #999da3;
  $bg0: white;
  $bg1: #ebedef;
  $text-color: #212121;
  $link-color: #385f9f;

  .os-page .min-bar {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
    border-top-color: $border-color;
    background-color: $bg0;
    border-top-width: 1px;
  }
  
  .os-page > .min-bar .rep {
    border-right-color: $border-color;
  }
  
  .os-page > .top-bar {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
    border-bottom-color: $border-color;
    background-color: $bg0;
    border-bottom-width: 1px;
  }
  
  .os-page > .min-bar .rep.window, .os-page > .min-bar .rep.active {
    background-color: $bg1;
  }


  
  .os-page > .min-bar > {
    .start, .browser {
      border-color: $border-color;
    }
  }
  
  .os-page > .top-bar > .views > .view {
    border-color: $border-color;
  }
  
  .os-page {
    // border-color: $border-color;
    // border-top-width: 2px;
  }
  
  .os-page > .top-bar > {
    .views {
      background-color: $bg0;
    }
  }
    
  .os-page > .top-bar > {
    .start.open {
      background-color: $bg1;
    }
  }
  
  .os-page > .area > .widgets > .column .widget {
    border-color: $border-color;
    box-shadow: 0px 0px 0px 2px rgba(148, 148, 148, 0.27);
    
    // padding: 13px;
    margin: 15px 8px;

    position: relative;

    // &:before {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   height: 103px;
    //   background-image: linear-gradient(180deg, white 0%, white 94%, #eaeaea 100%);
    //   // opacity: .5;
    //   content: '';
    // }


    > .title {
      font-size: 11px;
      background-color: $bg1;

      margin: -8px;
      padding: 4px 8px;
      margin-bottom: 5px;
      border-bottom: 1px solid #b1b1b1;
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
      box-sizing: border-box;
    }
  }

  
  .os-page > .area > .widgets > .column .widget:hover {
    box-shadow: 0px 0px 0px 3px rgba(148, 148, 148, 0.29);
  }
  
  .os-page > .area > .widgets > .column:first-child .widget {
    margin-left: 16px;
  }
  .os-page > .area > .widgets > .column:last-child .widget {
    margin-right: 16px;
  }
  
  .os-page > .area > .windows > .window {
    border-color: $border-color;
    border-width: 1px;
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }

  // .os-page > .area > .windows > .window,
  .os-page > .area > .widgets > .column .widget {
    // position: relative;

    > * {
      position: relative;
      z-index: 1;
    }

    // &:after {
    //   position: absolute;
    //   z-index: 0;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   height: 20px;
    //   background-image: linear-gradient(0, white 0%, white 74%, #ececec 100%);
    //   // opacity: .5;
    //   content: '';
    // }


    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 106px;
      background-image: linear-gradient(180deg, white 0%, white 94%, #ececec 100%);
      // opacity: .5;
      content: '';
    }

  }
  
  .os-page > .area > .windows > .window {
    background: linear-gradient(337deg, hsla(0, 0%, 98%, 1), #fff);

  }

  
  .os-page > .area > .windows > .window:not(.front) {
    // opacity: 0.5;
    border-color: $border-color;
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.07);
  
    .top-bar {
      background-color: $bg1;
      border-bottom-color: #c3c3c3;
    }
  }
  
  .os-page > .area > .windows > .window > .top-bar {
    border-color: $border-color;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
    background-color: $bg1;

  }
  
  .kNjWKu .client .nav {
    border-color: $border-color !important;
  }
  .os-page > .area {
    bottom: 0;
    &.showBar {
      bottom: 29px;
    }
    top: 0;
  }
  
  .os-page > .terminal {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
    border-top-color: $border-color;
    background-color: $bg0;
    border-top-width: 1px;
  
  }
  
  .os-page > .area > .widgets {
    background-color: $bg1;
  }

  .os-page .elements {
    button, span.button {
      // background-color: $bg1;
      background-image: linear-gradient(180deg, hsla(217, 6%, 97%, 1), #ebedef 46%, #ebedef 39%, hsla(217, 6%, 88%, 1) 100%);
    }
  }

  
  .os-page  .elements, .os-page .elements span.button, .os-page   .elements input[type="text"],
  .os-page > .area > .windows > .window > .top-bar > .title > .handle {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
    border-color: $border-color;
  }

  
  .os-page  .elements button, .os-page  .elements  input, .os-page   .elements textarea,
  .os-page   .elements a:visited, .os-page  .elements span.link:visited, .os-page  .elements span.button
  // .kDbcQA .resource, .kDbcQA .resource *
  {
    color: $text-color;
  }
  
  .os-page > .min-bar .rep.top, .os-page > .min-bar .rep.active {
    -webkit-text-stroke-color: $text-color;
  }

  .os-page > .terminal > .contents .line.active .in:before {
    border-color: $border-color;
  }


  .os-page > .min-bar > .menu {
    border-color: $border-color;
    border-width: 1px;
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .--around.parent--terminal > .autocomplete {
    border-color: $border-color;
    border-width: 1px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    overflow: hidden;

  }
  .os-page .elements a:hover, .os-page  .elements span.link:hover {
    color: $link-color;
  }
  .os-page .--around.parent--terminal > .autocomplete > span.active {
    background-color: $link-color;;
  }



  .os-page .min-bar,
  .os-page > .top-bar,
  .os-page > .top-bar > .views,
  .os-page > .terminal {
    background-color: $bg0;
  }

  .os-page .min-bar,
  .os-page > .top-bar,
  .os-page > .top-bar > .views {
    background-color: $bg1;
    background-image: linear-gradient(180deg, hsla(217, 6%, 97%, 1), #ebedef 46%, #ebedef 39%, hsla(217, 6%, 88%, 1) 100%);
  }

  .os-page > .min-bar .rep.window, .os-page > .min-bar .rep.active,
  .os-page > .top-bar > .start.open,
  .os-page > .area > .widgets > .column .widget > .title,
  .os-page > .area > .windows > .window .top-bar,
  .os-page > .area > .windows > .window:not(.front) .top-bar
  // .os-page > .area > .widgets
  {
    background-color: $bg1;
  }

  .os-page > .area > .widgets > .column .widget > .title,
  .os-page > .area > .windows > .window .top-bar,
  .os-page > .area > .windows > .window:not(.front) .top-bar
  {
    // background-image: linear-gradient(180deg, #ffffff, #dedede);
    // background-image: linear-gradient(180deg, #ffffff, #ebedef 46%, #ebedef 62%, #d8d8d8 100%);
    // background-image: linear-gradient(180deg, #ffffff, #ebedef 46%, #ebedef 62%, #dedada 100%);
    background-image: linear-gradient(180deg, hsla(217, 6%, 97%, 1), #ebedef 46%, #ebedef 39%, hsla(217, 6%, 88%, 1) 100%);
    // color: #6e7072;
  }

  .kDbcQA .resource, .kDbcQA .resource * {
    // color: #6e7072 !important;
  }

  .os-page > .area > .widgets {
    background-color: $bg1;
    background-color: hsla(217, 9%, 88%, 1);
    // background-image: linear-gradient(337deg, hsla(225, 5%, 83%, 1), hsla(225, 5%, 85%, 1));
  }

  .os-page .editing-value {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
    background-color: $bg0;
    // background-image: linear-gradient(180deg, hsla(217, 6%, 97%, 1), #ebedef 46%, #ebedef 39%, hsla(217, 6%, 88%, 1) 100%);
  }


  .os-page > .area > .widgets {
    // background-color: hsl(217, 16%, 75%);
  }

  .os-page > .area > .widgets > .column .widget {
    // box-shadow: 0px 0px 0px 2px rgba(148, 148, 148, 0.33);

  }


//   .os-page > .area > .widgets > .column .widget {

//     .title {



//       margin: 0;
//       padding: 0;
//       margin-bottom: 0;
//       border-bottom: none;
//       box-shadow: none;
//       box-sizing: border-box;
  
//       background-image: none;
//       background-color: transparent;
//     }
//   }

.os-page > .min-bar .rep.open, .os-page > .min-bar .rep.active {
  background-color: $bg0;
}

}
