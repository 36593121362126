

.--os {


	$black: #212121;
	$blue: #1a58bd;
	$border: #afafaf;
	$transition-duration: 100ms;
	
	@import './icons.css';

	overscroll-behavior-x: contain;

	$border-color: #1a58bd;
	$background-color: #e8e8e8;
	
	
	.windowTransitionMode-open {
		.window-enter {
			opacity: 0;
			transform: translateY(-35px) scale(.98);
		}
		.window-enter-active {
			opacity: 1;
			transform: translateY(0) scale(1);
			transition: all 100ms ease-out;
		}	
	}
	
	.windowTransitionMode-minimize {
		.window-exit {
			opacity: 1;
			transform: translateY(0) scale(1);
		}
		.window-exit-active {
			opacity: 0;
			transform: translateY(100px) scale(.9);
			transition: all 100ms ease-out;
		}	
	}
	
	.windowTransitionMode-maximize {
		.window-enter {
			opacity: 0;
			transform: translateY(100px) scale(.9);
		}
		.window-enter-active {
			opacity: 1;
			transform: translateY(0) scale(1);
			transition: all 100ms ease-out;
		}	
	}
	
	.windowTransitionMode-up {
		.window-exit {
			opacity: 1;
			transform: translateY(0) scale(1);
		}
		.window-exit-active {
			opacity: 0;
			transform: translateY(-100px) scale(.9);
			transition: all 100ms ease-out;
		}	
	}
	
	.windowTransitionMode-down {
		.window-enter {
			opacity: 0;
			transform: translateY(-100px) scale(.9);
		}
		.window-enter-active {
			opacity: 1;
			transform: translateY(0) scale(1);
			transition: all 100ms ease-out;
		}	
	}
	
	
	
	.windowTransitionMode-close {
		.window-exit {
			opacity: 1;
			transform: scale(1);
		}
		.window-exit-active {
			opacity: 0;
			transform: scale(.85);
			transition: all 150ms linear;
		}
	}
	
	.fade-enter {
		opacity: 0;
	}
	.fade-enter-active {
		opacity: 1;
		transition: all 100ms ease-out;
	}
	.fade-exit {
		opacity: 1;
	}
	.fade-exit-active {
		opacity: 0;
		transition: all 100ms linear;
	}
	
	
	.minWindow-enter {
		// opacity: 0;
		max-width: 0 !important;
		overflow: hidden;
	}
	.minWindow-enter-active {
		// opacity: 1;
		transition: all 100ms ease-out;
		max-width: 132px !important;;
	}
	
	.minWindow-exit {
		// opacity: 1;
		max-width: 132px;
		overflow: hidden;
	
	}
	.minWindow-exit-active {
		// opacity: 0;
		max-width: 0 !important;
		transition: all 100ms linear;
	}
	
	.window-enter {
		opacity: 0;
		transform: /*translateY(-35px)*/ scale(.98);
	}
	.window-enter-active {
		opacity: 1;
		transform: translateY(0) scale(1);
		transition: all 100ms ease-out;
	}
	.window-exit {
		opacity: 1;
		transform: scale(1);
	}
	.window-exit-active {
		opacity: 0;
		transform: scale(.95);
		transition: all 100ms linear;
	}
	
	.transition-terminal-enter {
		opacity: 0;
		transform: translateY(100px);
	}
	.transition-terminal-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: all 100ms ease-out;
	}
	.transition-terminal-exit {
		opacity: 1;
		transform: translateY(0)
	}
	.transition-terminal-exit-active {
		opacity: 0;
		transform: translateY(100px);
		transition: all 100ms linear;
	}
	
	.menu-transition-enter {
		opacity: 0;
		transform: translateY(20px);
	}
	.menu-transition-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: all 100ms ease-out;
	}
	.menu-transition-exit {
		opacity: 1;
		transform: translateY(0)
	}
	.menu-transition-exit-active {
		opacity: 0;
		transform: translateY(20px);
		transition: all 100ms linear;
	}
	
	
	.widget-enter {
		opacity: 0;
		transform: scale(.98);
		transition: none !important;
	}
	.widget-enter-active {
		opacity: 1;
		transform: translateY(0) scale(1);
		transition: transform 100ms linear, opacity 100ms linear !important;
	}
	
	.widget-exit {
		opacity: 1;
		transform: scale(1);
	}
	.widget-exit-active {
		opacity: 0;
		transform: scale(.95);
		transition: transform 100ms linear, opacity 100ms linear;
	}

		font-size: 12px;
		& {
			cursor: default;
		}

	body.defaultCursor {
		&, * {
			cursor: default !important;
		}
		a {
			text-decoration: none !important;
		}
	}



	.editing-value {
		// border: 1px dashed #bdbdbd;
		display: inline-block;
		padding: 4px;
		margin-top: -4px;
		margin-left: -4px;
		box-sizing: border-box;
		background-color: #ececec;
		box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.22);

		border-radius: 2px;
		input[type="text"] {
			width: 120px;
		}

		position: relative;
		z-index: 9999;

		button, input, input[type="text"] {
			border: none;;
		}
		> * {
			&:not(:last-child) {
				margin-right: 4px;
			}
		}
	}

	.editable-value {
		position: relative;

		&.empty {
			.editable-value__display {
				width: 4px;
				display: inline-block;
			}
		}
		&:hover {
			.editable-value__edit {
				pointer-events: all;
				opacity: 1;
				transform: scale(1);
			}
		}

		&.pre {
			.editable-value__edit  {
				right: 100%;
			}
		}

		.editable-value__edit {
			transform: scale(.9);
			transition: all 100ms linear;
			position: absolute;
			// left: calc(100%);
			opacity: 0;
			pointer-events: none;
			// top: 0;
			bottom: 0;
			margin: auto;
			z-index: 1000;
			margin-left: 0 !important;
			height: 16px;
		}

	}

	.editable {
		&.empty {
			display: inline-block;
		}
		min-width: 1ch;
		min-height: 1.08em;
		&:hover {
			border-bottom-color: transparent;
		}

		border-bottom: 1px dotted #bdbdbd;


		transition: border-bottom-color 100ms linear;
		position: relative;

		&:hover {
			button {
				pointer-events: all;
				opacity: 1;
				transform: scale(1);
			}
		}

		button {
			transform: scale(.9);
			transition: all 100ms linear;
			position: absolute;
			// left: calc(100%);
			opacity: 0;
			pointer-events: none;
			// top: 0;
			bottom: 0;
			margin: auto;
			z-index: 1000;
			margin-left: 0 !important;
			height: 16px;
		}
	}



	.has-wallpaper .os-page > .area > .widgets {
		background-color: transparent !important;
		background-image: none !important;
	}

	.inspectOverlay {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 9999999999999;
	}

	.os-page {

		.elements {
			button {
				vertical-align: middle;
			}

			button.windowAction, span.button.windowAction {
				width: 12px;
				padding: 0;
				height: 12px;
				border-radius: 50%;
				// border-radius: 3px;
				font-size: 6px;
				font-weight: bold;
		
				&:hover {
					&.close {
						background-color: #FF5F57;
					}
					&.minimize {
						background-color: #FFBE2F;
					}
					&.top, &.maximize {
						background-color: #2fd9ff;
					}
					&.embed {
						background-color: #28CA41;
					}
				}
			}
		}




		// border-top: 3px solid $border-color;
		flex-direction: column;

		.edit-widgets {
			.columns {
				border: 1px solid $border-color;
				border-radius: 2px;
				margin-top: 8px;
				background: #e6e6e6;
				padding: 0 4px;

				display: flex;
				> div {
					flex: 1 1 0;
					width: 0;

					> div {
						border: 1px solid $border-color;
						border-radius: 5px;
						margin: 8px 4px;
						padding: 8px;
						box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.33);
						transition: all 200ms linear;
						background-color: white;;
						overflow: hidden;

						&:hover {
							background: #f7f7f7;
							box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.33);

						}

					}
				}
			}
		}

		.interface-parameters {
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-size: 12px;
			> li {
				display: inline;
				> .name {
					font-weight: 500;
				}
			}
		}




		> .top-bar {
			height: 28px;
			border-bottom: 2px solid $border-color;
			display: flex;
			box-sizing: border-box;
			background-color: $background-color;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
			font-size: 12px;
			cursor: default;
			position: relative;
			z-index: 99999;

			> .views {
				display: flex;
				flex: 1 1 auto;
				z-index: 2;
				background: #e8e8e8;

				> .view {
					line-height: 27px;
					padding: 0 8px;
					display: flex;
					align-items: center;

					border-right: 1px solid $border-color;

					> .client {
						display: flex;
						align-items: center;
						> .title {
							font-size: 10px;
							margin-right: 5px;
							font-weight: 500;
							// &:after {
								// content: ': ';
							// } 
						}

					}

					> .maximize {
						margin-left: 4px;
					}
				}

			}

			> .activity {
				flex: 0 0 auto;
				line-height: 32px;
				text-align: center;
			}


		}

		.entity-ref {
			button {
				display: none;
			}
		}

		&:not(.min-bar) > .area {
			bottom: 0;
		}

		> .area {
			position: absolute;
			overflow: hidden;
			// top: 31px;
			top: 0;
			bottom: 30px;
			width: 100%;


			> .edit {
				position: absolute;
				bottom: 5px;
				right: 5px;
				z-index: 1;
				text-transform: lowercase;
				font-size: 13px;
			}

			> .widgets {
				height: 100%;
				display: flex;
				background-color: #e6e6e6;

				> .column {
					overflow-y: auto;
					overflow-x: hidden;
					box-sizing: border-box;
					z-index: 0;
					flex: 1 1 0;
					margin-left: -1px;

					// align-self: flex-start;
					max-height: 100%;

					// &:last-child {
					// 	border-right: none;
					// }

					// border-left: 1px solid $border-color;
					// border-right: 1px solid $border-color;


					&:first-child .widget {
						margin-left: 8px;
					}
					&:last-child .widget {
						margin-right: 8px;
					}
		

					.widget {
						overflow: hidden;
						border: 1px solid $border-color;
						border-radius: 6px;
						margin: 8px 4px;
						box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.22);
						
						flex-grow: 1;
						transition: all 200ms linear;
						background: white;
						// &:hover {
						// 	background: #f7f7f7;
						// 	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.33);
						// }

						> .title:not(:hover) {
							> .buttons {
								opacity: 0;
								transform: scale(.9);
							}
						}

						// border-bottom: 1px solid $border-color;

						padding: 8px;

						> .title {
							display: block;
							// text-align: center;
							// display: flex;
							// align-items: center;
							font-size: 15px;
							position: relative;
							&[data-react-beautiful-dnd-drag-handle="0"] {
								cursor: inherit;
							}

							> .buttons {
								transition: all 200ms linear;
								position: absolute;
								right: 8px;
								top: 3px;

								align-self: flex-start;

								> * {
									margin-left: 2px;
								}
							}

							button {
								margin-left: 3px;
							}
						}

						ul:not(.parameters) {
							margin: 2px 0;
						}
					}
				}
			}


				// > * {
				// 	width: 50%;

				// 	&:first-child {
				// 		border-right: 2px solid $border-color;
				// 	}
				// }

			> .windows {
				position: absolute;
				top: 0;
				z-index: 999999;
				// overflow: hidden;
				> .window {
					position: absolute;
					border: 2px solid $border-color;
					background: white;
					box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.1);
					border-radius: 5px;
					overflow: hidden;
					z-index: 0;

					display: flex;
					flex-direction: column;

					.blGrip {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 6px;
						height: 6px;
						cursor: nesw-resize;
						z-index: 9999;
					}
					.brGrip {
						position: absolute;
						bottom: 0;
						right: 0;
						width: 6px;
						height: 6px;
						cursor: nwse-resize;
						z-index: 9999;
					}

					&:not(.front) {
						border-color: gray;
						> .top-bar {
							// background-color: white;
							&, * {
								color: gray;
							}
							border-color: gray;

							> .title > .handle {
								border-color: gray;
							}

							// > .buttons {
								button, span.button {
									color: gray;
									border-color: gray;
								}
							// }
						}
					}

					> .top-bar {
						position: relative;
						display: flex;
						border-bottom: 1px solid $border-color;
						background-color: $background-color;
						// height: 21px;
						align-items: center;
						padding: 0 4px;
						// margin: 0 4px;
						overflow: hidden;

						flex: 0 0 auto;
						box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);

						.react-contextmenu-wrapper {
							position: absolute;
							right: 0;
							top: 0;
							bottom: 0;
							width: 30px;
						}


						> .title {
							font-size: 12px;
							text-align: center;
							display: block;
							cursor: default;
							// font-weight: bold;
							position: absolute;
							left: 40px;
							right: 40px;
							top: 0;
							height: 100%;

							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;

							display: flex;
							justify-content: center;
							align-items: center;



							> .handle {
								display: inline-block;
								display: none;
								width: 10px;
								height: 10px;
								border: 1px solid $black;
								vertical-align: middle;
								margin-right: 4px;
								border-radius: 3px;
								box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
								background-color: white;
								cursor: default;
							}
						}

						> .buttons {
							position: relative;
							display: flex;
							flex-direction: row-reverse;
							height: 21px;
							align-items: center;
							margin-right: 6px;
							// align-self: flex-start;
							button, span.button {
								margin-right: 2px;
							}
						}
					}

					> .client {
						overflow: auto;
						// top: 22px;
						// left: 0;
						width: 100%;
						// bottom: 0;
						padding: 8px;
						box-sizing: border-box;
						flex: 1 1 auto;
						position: relative;
					}
				}
			}

			.maximizedWindow {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				background-color: white;
			}
		}


		> .min-bar {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 28px;
			display: flex;
			border-top: 2px solid $border-color;
			background-color: $background-color;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
			font-size: 12px;
			z-index: 10000000;



			> .start {
				width: 64px;
				flex: 0 0 auto;
				line-height: 26px;
				text-align: center;
				font-size: 12px;
				font-weight: 500;
				border-right: 1px solid $border-color;
				z-index: 2;

				transition: background-color 100ms linear;
				cursor: pointer;

				&.open {
					background-color: white;
					font-weight: bold;
				}
			}

			> .menu {
				z-index: 1;
				position: absolute;
				bottom: 26px;
				left: 0;

				border: 2px solid $border-color;
				border-top-width: 1px;
				background: white;
				box-shadow: 0px 0px 9px 6px rgba(0, 0, 0, 0.1);
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				padding: 4px;

				max-height: calc(100vh - 89px);;
				overflow: auto;

				.selector input[type="text"] {
					width: 100%;
					box-sizing: border-box;
				}
				.selector {
					margin-bottom: 2px;
					display: block;
				}
				a {
					color: $border-color;
				}

				ul {
					margin: 0;
					padding: 0;
					list-style-type: none;
				}

				.title {
					font-weight: 500;
				}

				.section {
					margin-top: 4px;
				}
			}

			> .browser {
				// width: 64px;
				padding: 0 8px;
				flex: 0 0 auto;
				line-height: 26px;
				text-align: center;
				font-size: 12px;
				// font-weight: 500;
				border-right: 1px solid $border-color;
				z-index: 2;

				transition: background-color 100ms linear;
				cursor: pointer;
				background-color: #e8e8e8;
			}



			.reps {
				height: 28px;
				display: flex;	
			}

			&.terminalOpen {
				border-top-width: 1px;
			}

			.rep {
				line-height: 28px;
				padding: 0 8px;
				border-right: 1px solid $border-color;
				cursor: default;
				cursor: pointer;


				transition: all 100ms linear;

				max-width: 132px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;


				&.window, &.active {
					background-color: white;
				}

				&.top, &.active {
					-webkit-text-stroke-width: 0.8px;
					-webkit-text-stroke-color: #212121;
				}
			}	
		}

		.interface {
			position: relative;

			&.errored {
				border: 1px dashed rgba(255, 0, 0, .5);
				border-radius: 3px;
			}

			// > .react-contextmenu-wrapper {
			// 	position: absolute;
			// 	left: 0;
			// 	right: 0;
			// 	top: 0;
			// 	bottom: 0;
			// 	z-index: 0;
			// }
		}

		.configure-interface {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 4px;
			}
			.name {
				font-weight: 500;
			}
		}


		.interface.top {
			line-height: normal;
			p {
				margin: 0;

			}

			div {
				display: inline-block;
			}
		}
		.--content {
			// margin-top: 8px;
		}

		.jqiAoN .id {
			color: #92959b;
		}
	}

	$border-color: #999da3;
	$bg0: white;
	$bg1: #ebedef;
	$text-color: #212121;
	$link-color: #385f9f;

	.cont-topBar {
		background-color: #ebedef;
		background-image: linear-gradient(180deg, #f7f7f8, #ebedef 46%, #ebedef 39%, #dfe0e2 100%);

		> * {
			border-right: 1px solid #999da3;
		}




			.widget {
				.parameters {
					display: none;
				}
				div, ul, li {
					display: inline;
				}
		
				> .title {
					font-size: 10px;
					margin-right: 5px;
					font-weight: 500;
				}
		
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0 8px;
		
				font-size: 11px;
		
			}


	}

	.cont-columns {
		// padding: 8px;
		box-sizing: border-box;
		overflow: auto;
		> * {
			&:not(:first-child) {
				// margin-left: 8px;
			}
		}
	}

	.cont-widgets {
		> * {
			&:not(:first-child) {
				margin-top: 8px;
			}
		}
		overflow: auto;
		padding: 8px 4px;

		&:first-child {
			padding-left: 8px; 
		}

		&:last-child {
			padding-right: 8px;
		}

		.widget {
			border-color: $border-color;
			box-shadow: 0px 0px 0px 2px rgba(148, 148, 148, 0.27);
		
			border: 1px solid $border-color;
			border-radius: 6px;
			// margin: 8px 4px;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.22);
			
			background: white;

			> .title {
				display: block;
				width: 100%;
				font-size: 11px;
				background-color: $bg1;
				padding: 4px 8px;
				border-bottom: 1px solid #b1b1b1;
				box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.09);
				box-sizing: border-box;
			}
		
			> .content {
				padding: 8px;
			}
		
		}
	}

	.widget {
		overflow: hidden;




	}


	.elements {
		button, span.button, a, span.link {
			cursor: pointer;
			&:active {
				// opacity: .7 !important;
			}
		}
		
		button, span.button {
			&:not([disabled]) {
				&:hover {
					transform: scale(1.05) !important;
				}
				&:active {
					transform: scale(.98) !important;
				}	
			}
		}
		
			&, button, input, textarea {
				font-family: Helvetica Neue, Helvetica;		
				color: $black;
			}
		
		
		a, span.link {
			transition: color 100ms linear;
		
			&:visited {
				color: $black;
			}
			text-decoration: none;
			// border-bottom: 1px solid transparent;
			// transition: border-bottom-color $transition-duration linear;
			&:hover {
				color: $blue;
				text-decoration: underline;
				// border-bottom: 1px solid #4d4dfb;
				// border-bottom-color: #4d4dfb;
			}
		}
		
		span.button {
			display: inline-flex;
			box-sizing: border-box;
		
			display: inline-flex;
			justify-content: center;
			align-items: center;
			> * {
				flex: 0 0 auto;
			}
		
			vertical-align: middle;
		}
		
		button.windowAction, span.button.windowAction {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			> * {
				flex: 0 0 auto;
			}
		
			.gg {
				--ggs: .4;
		
				&.gg-minimize {
					--ggs: .3;
				}
		
				&.gg-math-minus {
					--ggs: .3;
				}
			}
		}
		
		button, span.button {
			border: 1px solid $black;
			background-color: white;
			border-radius: 4px;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
		
			color: $black;
			font-size: 9px;
			transition: all $transition-duration linear;
		
			&:not([disabled]) {
		
				// &:hover {
				// 	background-color: $black;
				// 	color: white;
				// 	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);
				// }
			}
		
		
			&:focus {
				outline: none;
			}
		
			&[disabled] {
				opacity: .5;
			}
		}
		
		input[type="text"] {
			border: 1px solid $black;
			padding: 5px;
			border-radius: 4px;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
			transition: all $transition-duration linear;
		
			&:focus {
				outline: none;
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);
			}
		
		}
		
	}

	.disconnected {
		background-color: red;
		color: white;
		position:fixed;
		right: 0;
		bottom: 0;
		z-index: 9999999999;
		padding: 5px;
		border-top-left-radius: 4px;
	}

	.--around.parent--selector > .autocomplete {
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
		background-color: white;
		margin: 0;
		left: 0;
		list-style-type: none;
		padding: 3px;
		li {
			white-space: nowrap;
		}

		.selected {
			// font-weight: bold;
			background-color: #b7b7b7;
		}
	}

	.value-content {
		.value-content__save, .value-content__edit {
			margin-left: 6px;
		}
	}


	.value-content-field {
		.value-content-field__quick-entities {
			button {
				margin-left: 6px;
			}
		}
	}


	.react-contextmenu {
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid rgba(0,0,0,.15);
			border-radius: .25rem;
			color: #373a3c;
			font-size: 16px;
			margin: 2px 0 0;
			min-width: 160px;
			outline: none;
			opacity: 0;
			padding: 5px 0;
			pointer-events: none;
			text-align: left;
			transition: opacity 250ms ease !important;
	}

	.react-contextmenu.react-contextmenu--visible {
			opacity: 1;
			pointer-events: auto;
			z-index: 9999;
	}

	.react-contextmenu-item {
			background: 0 0;
			border: 0;
			color: #373a3c;
		cursor: pointer;
			font-weight: 400;
			line-height: 1.5;
			padding: 3px 20px;
			text-align: inherit;
			white-space: nowrap;
	}

	.react-contextmenu-item.react-contextmenu-item--active,
	.react-contextmenu-item.react-contextmenu-item--selected {
			color: #fff;
			background-color: #20a0ff;
			border-color: #20a0ff;
			text-decoration: none;
	}

	.react-contextmenu-item.react-contextmenu-item--disabled,
	.react-contextmenu-item.react-contextmenu-item--disabled:hover {
			background-color: transparent;
			border-color: rgba(0,0,0,.15);
			color: #878a8c;
	}

	.react-contextmenu-item--divider {
			border-bottom: 1px solid rgba(0,0,0,.15);
			cursor: inherit;
			margin-bottom: 3px;
			padding: 2px 0;
	}
	.react-contextmenu-item--divider:hover {
			background-color: transparent;
			border-color: rgba(0,0,0,.15);
	}

	.react-contextmenu-item.react-contextmenu-submenu {
		padding: 0;
	}

	.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
			content: "▶";
			display: inline-block;
			position: absolute;
			right: 7px;
	}


	
	
}

@import './light-theme.scss';
